<template>
    <div class="reports">
        <dl>
        <dt><a href="#">Export Study Selection to Excel</a></dt>
        <dt><a href="#">Export Data Extraction to Excel</a></dt>
        <dt><a href="#">Tag Report</a></dt>
        </dl>
    </div>
</template>

<script>
export default {
  name: 'reports',
  methods: {
    openStageHistoryByPublication: function () {
      // this.$router.push({ path: '/Secure/reports/StageHistoryByCitation' })
    },
    openCitationsWithAnnotationsDiscrepancies: function () {
      this.$router.push({ path: '/Secure/reports/CitationsWithAnnotationsDiscrepancies' })
    },
    openMetricsReport: function () {
      this.$router.push({ path: '/Secure/reports/OpenMetricsReport' })
    },
    openPotentialDuplicates: function () {
      this.$router.push({ path: '/Secure/reports/PotentialDuplicates' })
    },
    openDataExtractionReport: function () {
      console.log(0)
      this.$router.push({ path: '/Secure/reports/DataExtractionReport' })
    }
  }
}
</script>
